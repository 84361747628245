<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            :title="title"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <van-form @submit="onSubmit">
      <div class="section-box" style="padding: 5px">
        <van-field
            v-model="oldPass"
            name="oldPass"
            type="password"
            label="原密码"
            left-icon="lock"
            placeholder="原密码"
            required
            :rules="[{ required: true, message: '' }]"
        />
        <van-field
            v-model="newPass"
            name="newPass"
            type="password"
            label="新密码"
            left-icon="lock"
            placeholder="新密码"
            required
            :rules="[{ required: true, message: '' }]"
        />
        <van-field
            v-model="newPass1"
            name="newPass1"
            label="确认新密码"
            left-icon="lock"
            type="password"
            placeholder="确认新密码"
            required
            :rules="[{ required: true, message: '' }]"
        />
        <div style="margin: 16px;">
          <van-button type="info" round block>修改</van-button>
        </div>
      </div>
    </van-form>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {modifyPass} from "../../api";

export default {
  name: "modifyPhone",
  components: { Loading },
  data() {
    return {
      isShow: false,
      lodingTxt: '加载中...',
      title: '修改密码',
      oldPass:'',
      newPass:'',
      newPass1:''
    }
  },
  created() {

  },
  methods:{
    /**
     * 提交
     */
    onSubmit() {
      const that = this
      if (that.oldPass == that.newPass){
        that.$toast('原密码与新密码相同')
        return
      }
      if (that.newPass1 != that.newPass){
        that.$toast('两次密码输入不一致')
        return
      }
      const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
      if(!pwdRegex.test(that.newPass)){
        that.$toast('新密码必须包含字母、数字，8-30位')
        return
      }
      if(!pwdRegex.test(that.newPass)){
        that.$toast('确认新密码必须包含字母、数字，8-30位')
        return
      }
      that.lodingTxt = '正在保存...'
      that.isShow = true
      modifyPass(that.oldPass,that.newPass,that.newPass1).then(response => {
        that.isShow = false
        that.$toast(response.message)
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.oldPass = ''
          that.newPass = ''
          that.newPass1 = ''
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>